<template>
  <div>
    <b-card>
      <b-overlay :show="loading">
        <h1>Usuarios</h1>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'Usuarios',
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
    this.getUsuarios()
  },
  methods: {
    getUsuarios() {
      this.$store.dispatch('usuarios/getUsuarios')
    },
  },
}
</script>

<style scoped>

</style>
